import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { ErrorsComponent } from '../../errors/errors.component';

@Component({
  selector: 'app-generic-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    NgxMaskDirective,
    ErrorsComponent,
  ],
  templateUrl: './generic-input.component.html',
  styleUrl: './generic-input.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericInputComponent implements OnInit, AfterViewChecked {
  @Input() id!: string;
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() controlName!: string;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() allowNegativeNumbers: boolean = false;
  @Input() form!: FormGroup;
  @Input() mask: string = '';
  @Input() clearIfNotMatch: boolean = false;
  @Input() personalityStyle: string = '';
  @Input() thousandSeparator: string = '';
  @Input() decimalMarker: string = '.';
  @Input() min: number | null = null;
  @Input() max: number | null = null;

  control!: FormControl;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.control = this.form.get(this.controlName) as FormControl;

    this.control.valueChanges.subscribe(() => {
      if (this.control.invalid) {
        this.control.markAsTouched();
      }
    });
  }

  getClasses(): string {
    const defaultClasses =
      'flex-1 h-12 rounded-md outline-none p-3 text-base font-extralight text-tertiary';
    return this.personalityStyle
      ? `${defaultClasses} ${this.personalityStyle}`
      : defaultClasses;
  }

  ngAfterViewChecked(): void {
    this._cdr.detectChanges();
  }

  markAsTouched(): void {
    this.control.markAsTouched();
  }

  handleInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (this.type === 'number') {
      const value = inputElement.value ? parseFloat(inputElement.value) : null;
      this.control.setValue(value);
    } else {
      this.control.setValue(inputElement.value);
    }
  }
}
